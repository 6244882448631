import React, { Component } from 'react';
import { createUser } from '../repositories/firebaseRepository';
import { Button, TextField } from '@material-ui/core';
import { Loader } from '../components/Loader';

export class CreateAccountForm extends Component {
    state = {
        email: '',
        password: 'Password000',
        confirmPassword: 'Password000',
        adNetwork: '',
        loading: false
    };

    setField = field => e => {
        this.setState({
            [field]: e.target.value
        });
    };

    verifyFields = () => {
        const { email, password, adNetwork } = this.state;
        if (!email || !password || !adNetwork) {
            this.props.showSnackbar('20 IQ Spotted \\\\ -(°°)- //');
            return true;
        }
        return false;
    };

    submit = async e => {
        const { email, password, adNetwork } = this.state;
        const { firebaseToken, showSnackbar } = this.props;
        this.setState({ loading: true });
        e.preventDefault();
        if (this.verifyFields()) {
            this.setState({ loading: false });
            return;
        }
        const { res } = await createUser(firebaseToken, {
            email,
            password,
            adNetwork
        });
        this.setState({ loading: false });
        showSnackbar(res);
    };

    render() {
        const { email, password, adNetwork, loading } = this.state;
        return (
            <form onSubmit={this.submit}>
                <TextField
                    style={styles.inputStyle}
                    onChange={this.setField('email')}
                    value={email}
                    placeholder="Email"
                />
                <TextField
                    style={styles.inputStyle}
                    onChange={this.setField('password')}
                    value={password}
                    placeholder="Password"
                />
                <TextField
                    style={styles.inputStyle}
                    onChange={this.setField('adNetwork')}
                    value={adNetwork}
                    placeholder="Ad Network"
                />
                <Button type="submit" disabled={loading}>
                    {loading ? <Loader size={20} /> : 'Créer'}
                </Button>
            </form>
        );
    }
}

const styles = {
    inputStyle: {
        margin: '5px 20px'
    }
};
