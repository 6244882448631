export const BASE_URL = 'https://us-central1-attributionapi-22fbe.cloudfunctions.net';
// export const BASE_URL = ' http://localhost:5000/attributionapi-22fbe/us-central1';

export const config = {
    apiKey: 'AIzaSyDl0mcOkJ7rHkm8Hr__XKtwMvu2xvxmeRI',
    authDomain: 'attributionapi-22fbe.firebaseapp.com',
    databaseURL: 'https://attributionapi-22fbe.firebaseio.com',
    projectId: 'attributionapi-22fbe',
    storageBucket: '',
    messagingSenderId: '676999320387'
};
