import React, { Component } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Table } from '../Table';

const MyTableCell = ({ children, ...props }) => (
    <TableCell style={{ textAlign: 'left' }} {...props}>
        {children}
    </TableCell>
);

export class RetributionTable extends Component {
    state = {};

    renderTitleCells = () => [
        <MyTableCell key="SegmentName">Nom du Segment</MyTableCell>,
        <MyTableCell key="Contribution" numeric>
            Contribution
        </MyTableCell>,
        <MyTableCell key="dataRevenue" numeric>
            Revenu
        </MyTableCell>
    ];

    renderRowCells = () => {
        const { currentSite } = this.props;
        if (!currentSite.retribution || !currentSite.segments) {
            return [];
        }
        return Object.keys(currentSite.segments).map(segmentKey => (
            <TableRow key={0} style={{ textAlign: 'right' }}>
                <TableCell>{segmentKey}</TableCell>
                <TableCell component="th" scope="row">
                    A venir
                </TableCell>
                <TableCell component="th" scope="row">
                    {currentSite.segments[segmentKey].toFixed(2)}
                </TableCell>
            </TableRow>
        ));
    };

    render() {
        return <Table TitleCells={this.renderTitleCells()} RowCells={this.renderRowCells()} />;
    }
}
