import React from "react";
import { Title } from "../components/labels/Title";

export const pages = WrappedComponent => ({ children, ...props }) => (
    <div style={styles.page}>
        <Title>Mon Profile</Title>
        <WrappedComponent style={styles.container} {...props}>
            {children}
        </WrappedComponent>
    </div>
);

const styles = {
    page: {
        display: "flex",
        padding: "2% 12%",
        flexDirection: "column"
    },
    container: {
        display: "flex"
    }
};
