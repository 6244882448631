import React from 'react';
import PropTypes from 'prop-types';
import { Table as MuiTable, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';

const EmptyRow = () => (
    <TableRow key={0}>
        <TableCell component="th" scope="row">
            Aucune donnée
        </TableCell>
    </TableRow>
);

export const Table = ({ TitleCells, RowCells }) => {
    return (
        <MuiTable>
            <TableHead>
                <TableRow>{TitleCells}</TableRow>
            </TableHead>
            <TableBody>{RowCells.length > 0 ? RowCells : <EmptyRow />}</TableBody>
        </MuiTable>
    );
};

Table.propTypes = {
    TitleCells: PropTypes.arrayOf(PropTypes.node).isRequired,
    RowCells: PropTypes.arrayOf(PropTypes.node).isRequired
};
