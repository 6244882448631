import React from 'react';
import { ChangePasswordForm } from '../forms/ChangePassword.form';
import { Paper } from '@material-ui/core';
import { pages } from '../hoc/pages';

const PureProfile = ({ style, ...props }) => (
    <Paper style={{ padding: '0px 30px', alignSelf: 'center', ...style }}>
        <div style={{ padding: '30px 0px', flexDirection: 'column' }}>
            <h4>Changer mon mot de passe</h4>
            <ChangePasswordForm {...props} />
        </div>
    </Paper>
);

export const Profile = pages(PureProfile);
