import React, { Component } from 'react';
import '../App.css';
import { Button } from '@material-ui/core';
import { CustomLoginForm } from '../forms/CustomLogin.form';
import * as firebase from 'firebase';

const DBM_SCOPE = 'https://www.googleapis.com/auth/doubleclickbidmanager';

let provider = new firebase.auth.GoogleAuthProvider();
provider.addScope(DBM_SCOPE);

export class LoginPage extends Component {
    state = {
        loading: false
    };

    firebaseLogin = (providerAuth = false) => async (email, password) => {
        try {
            const result = providerAuth
                ? await firebase.auth().signInWithPopup(provider)
                : await firebase.auth().signInWithEmailAndPassword(email, password);
            const firebaseToken = await firebase.auth().currentUser.getIdToken(true);
            const user = result.user;
            const googleApisToken = providerAuth ? result.credential.accessToken : 'NoAccessToken';
            this.props.showSnackbar(`Welcome ${user.displayName || ''}`);
            this.props.setTokens(firebaseToken, googleApisToken);
        } catch (error) {
            console.error(error);
            this.props.showSnackbar(error.message);
        }
    };

    render() {
        const { showSnackbar } = this.props;
        return (
            <div style={styles.container}>
                <h1>Alliance Retribution Manager</h1>
                <CustomLoginForm
                    style={{
                        color: 'white',
                        alignItems: 'center'
                    }}
                    login={this.firebaseLogin(false)}
                    showSnackbar={showSnackbar}
                />
                <div style={styles.providerButtonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '5px', position: 'absolute', bottom: '10px', right: '10px' }}
                        onClick={this.firebaseLogin(true)}
                    >
                        MEMBRE JOIN
                    </Button>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        textAlign: 'center',
        backgroundColor: '#282c34',
        minHeight: '100vh',
        color: 'white',
        paddingTop: '6%'
    }
};
