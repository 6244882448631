import React, { Component } from 'react';
// import { createUser } from "../repositories/firebaseRepository";
import { Button, TextField } from '@material-ui/core';
import { Loader } from '../components/Loader';

export class CustomLoginForm extends Component {
    state = {
        email: '',
        password: '',
        loading: false
    };

    setField = field => e => {
        this.setState({
            [field]: e.target.value
        });
    };

    verifyFields = () => {
        const { email, password } = this.state;
        const { showSnackbar } = this.props;
        if (!email || !password) {
            showSnackbar('Merci de renseigner les informations correctement');
            return true;
        }
        return false;
    };

    submit = async e => {
        const { email, password } = this.state;
        // const { firebaseToken } = this.props;
        this.setState({ loading: true });
        e.preventDefault();
        if (this.verifyFields()) return;
        await this.props.login(email, password);
        this.setState({ loading: false });
    };

    render() {
        const { email, password, loading } = this.state;
        const { style } = this.props;
        return (
            <form style={{ ...styles.container, ...style }} onSubmit={this.submit}>
                <TextField
                    style={styles.inputStyle}
                    inputProps={{
                        style: { color: 'white', borderBottomColor: 'red' }
                    }}
                    onChange={this.setField('email')}
                    value={email}
                    placeholder="Email"
                />
                <TextField
                    style={styles.inputStyle}
                    type="password"
                    inputProps={{ style: { color: 'white' } }}
                    onChange={this.setField('password')}
                    value={password}
                    placeholder="Mot de passe"
                />

                <Button
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    style={{ marginTop: '10px' }}
                >
                    {loading ? <Loader /> : 'Se connecter'}
                </Button>
            </form>
        );
    }
}

CustomLoginForm.defaultProps = {
    style: {}
};
const styles = {
    inputStyle: {
        margin: '15px',
        minWidth: '300px'
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingTop: '1%'
    }
};
