import * as firebase from 'firebase';
import { BASE_URL } from '../conf';

const authorizedBody = (firebaseToken, body) => ({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${firebaseToken}`
    },
    body: JSON.stringify(body)
});

export const updateRetribution = async (oauthToken, userIdToken) => {
    console.log(userIdToken);
    const response = await fetch(BASE_URL + '/updateRetribution', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userIdToken}`
        },
        body: JSON.stringify({
            oauthToken: oauthToken
        })
    });
    return await response.json();
};

export const createUser = async (firebaseToken, body) => {
    const response = await fetch(BASE_URL + '/createUser', authorizedBody(firebaseToken, body));
    return await response.json();
};

export const changePassword = async newPassword => {
    const user = firebase.auth().currentUser;
    try {
        await user.updatePassword(newPassword);
        return { error: false, message: 'Password has been changed' };
    } catch (e) {
        console.error('Error while changing password', e.message);

        return {
            error: true,
            message: e.message
        };
    }
};
