import React, { Component } from 'react';
import { AppBar as MuiAppBar, Toolbar, Typography, IconButton, MenuItem, Menu } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';

export class AppBar extends Component {
    state = {
        auth: true,
        anchorEl: null
    };

    handleChange = event => {
        this.setState({ auth: event.target.checked });
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    renderTitle = title => `Retribution Dashboard ${title ? ' - ' + title : ''}`;

    render() {
        const { appBarTitle, logout } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <MuiAppBar style={{ flexDirection: 'row' }} position="static" color="primary">
                <Toolbar
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="h6" color="inherit">
                        {this.renderTitle(appBarTitle)}
                    </Typography>
                    <div>
                        <IconButton
                            aria-owns={open ? 'menu-appbar' : null}
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={open}
                            onClose={this.handleClose}
                        >
                            <MenuItem onClick={this.handleClose}>
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black'
                                    }}
                                    to={'/dashboard'}
                                >
                                    Dashboard
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={this.handleClose}>
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: 'black'
                                    }}
                                    to={'/profile'}
                                >
                                    Profile
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </MuiAppBar>
        );
    }
}
