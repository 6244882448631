import React, { Component } from 'react';
import { CircularProgress, Paper } from '@material-ui/core';
import { AppBar, Typography } from '@material-ui/core';
import { RetributionTable } from '../components/tables/RetributionTable';
import * as firebase from 'firebase';

export class Dashboard extends Component {
    state = {
        loading: false,
        adNetwork: {}
    };

    async componentWillMount() {
        const { user, setAppBarTitle } = this.props;

        this.setState({ loading: true });
        const snap = await firebase
            .database()
            .ref(`/${user.adNetwork}`)
            .once('value');
        const adNetwork = snap.val();

        const appBarTitle = adNetwork ? adNetwork.name : 'AUCUN SITE';
        setAppBarTitle(appBarTitle);
        this.setState({ adNetwork, loading: false });
    }

    render() {
        const { loading, adNetwork } = this.state;
        return loading ? (
            <CircularProgress style={styles.globalLoader} size={25} />
        ) : (
            <div style={styles.container}>
                <>
                    {adNetwork && adNetwork.sites.length > 0 ? (
                        adNetwork.sites.map(site => (
                            <Paper style={styles.paper}>
                                <AppBar position="static">
                                    <Typography style={{ margin: '8px 0px' }} variant="h6" color="inherit">
                                        {site.name}
                                    </Typography>
                                </AppBar>
                                <p>Revenu total {site.retribution.toFixed(2)} €</p>
                                <RetributionTable currentSite={site} />
                            </Paper>
                        ))
                    ) : (
                        <Paper style={{ padding: '10px' }}>
                            <p>Aucun sites n'a encore été associé à votre compte</p>
                        </Paper>
                    )}
                </>
            </div>
        );
    }
}

const styles = {
    globalLoader: { position: 'absolute', top: '50%', left: '50%' },
    container: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '5%'
    },
    updateDataButton: {
        height: '25px',
        borderRadius: '8px',
        margin: '20px'
    },
    adminContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px'
    },
    paper: { width: '66%', marginTop: '50px' }
};
