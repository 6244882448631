import React, { Component } from 'react';
import { changePassword } from '../repositories/firebaseRepository';
import { Loader } from '../components/Loader';
import { Button, TextField } from '@material-ui/core';

export class ChangePasswordForm extends Component {
    state = {
        newPassword: '',
        confirmNewPassword: '',
        loading: false
    };

    setField = field => e => {
        this.setState({
            [field]: e.target.value
        });
    };

    verifyFields = () => {
        const { newPassword, confirmNewPassword } = this.state;
        const { showSnackbar } = this.props;
        if (!newPassword || !confirmNewPassword) {
            showSnackbar('Merci de renseigner des mots de passe identiques');
            return true;
        }
        return false;
    };

    submit = async e => {
        const { newPassword } = this.state;
        const { showSnackbar } = this.props;
        e.preventDefault();
        if (this.verifyFields()) return;
        this.setState({ loading: true });
        const res = await changePassword(newPassword);
        this.setState({ loading: false });
        showSnackbar(res.message);
    };

    render() {
        const { email, password, loading } = this.state;
        const { style } = this.props;
        return (
            <form
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '20px 0px',
                    ...style
                }}
                onSubmit={this.submit}
            >
                <TextField
                    style={styles.inputStyle}
                    onChange={this.setField('newPassword')}
                    value={email}
                    type="password"
                    placeholder="New password"
                />
                <TextField
                    style={styles.inputStyle}
                    onChange={this.setField('confirmNewPassword')}
                    value={password}
                    type="password"
                    placeholder="Confirm new password"
                />

                <Button
                    style={{ width: '250px', marginTop: '5px' }}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={loading}
                >
                    {loading ? <Loader size={20} /> : 'Changer Mot de Passe'}
                </Button>
            </form>
        );
    }
}

const styles = {
    inputStyle: {
        margin: '18px 5px',
        width: '300px'
    }
};
