import React, { Component } from 'react';
import { updateRetribution } from '../repositories/firebaseRepository';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import CloudUploadIcon from '../../node_modules/@material-ui/icons/CloudUpload';
import { Title } from '../components/labels/Title';
import { CreateAccountForm } from '../forms/CreateAccount.form';

export class AdminDashboard extends Component {
    state = {
        loading: false,
        adNetwork: {}
    };

    updateRetributionHandler = async () => {
        const { googleApisToken, firebaseToken } = this.props;
        this.setState({ loading: true });
        const response = await updateRetribution(googleApisToken, firebaseToken);
        this.props.showSnackbar(response.res);
        this.setState({
            loading: false
        });
    };

    renderRetributionButton = () => {
        const { loading } = this.state;
        return (
            <div>
                {loading ? (
                    <CircularProgress size={25} />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        style={styles.updateDataButton}
                        onClick={this.updateRetributionHandler}
                    >
                        Update retribution data
                        <CloudUploadIcon style={{ marginLeft: '8px' }} />
                    </Button>
                )}
            </div>
        );
    };

    renderCreateAccount = (firebaseToken, showSnackbar) => {
        return (
            <Paper elevation={2} style={{ padding: '20px 60px 40px 60px', margin: '10px' }}>
                <Title style={{ textAlign: 'left' }}>Créer un utilisateur</Title>
                <CreateAccountForm firebaseToken={firebaseToken} showSnackbar={showSnackbar} />
            </Paper>
        );
    };

    render() {
        const { firebaseToken, showSnackbar } = this.props;
        return (
            <div style={styles.container}>
                <div style={styles.adminContainer}>
                    {this.renderRetributionButton()}
                    {this.renderCreateAccount(firebaseToken, showSnackbar)}
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },

    adminContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px'
    }
};
