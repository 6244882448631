import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme, CircularProgress } from '@material-ui/core';
import { AppBar } from './components/AppBar';
import { Dashboard } from './pages/Dashboard';
import { Profile } from './pages/Profile';
import { LoginPage } from './pages/Login';
import { Snackbar } from './components/Snackbar';
import { AdminDashboard } from './pages/AdminDashboard';
import * as firebase from 'firebase';

const theme = createMuiTheme({
    palette: {
        // type: "dark"
    },
    typography: {
        useNextVariants: true
    }
});

const ProtectedRoute = ({ predicate, children, redirectTo, path }) => (
    <Route
        path={path}
        render={props =>
            predicate ? (
                children(props)
            ) : (
                <Redirect
                    to={{
                        pathname: redirectTo,
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

class App extends Component {
    state = {
        showSnackbar: false,
        snackbarMessage: '',
        firebaseToken: '',
        googleApisToken: '',
        appBarTitle: '',
        user: {
            isAdmin: false,
            adNetwork: ''
        },
        loading: true
    };

    setAppBarTitle = appBarTitle => {
        this.setState({ appBarTitle });
    };

    showSnackbar = snackbarMessage => {
        this.setState({ showSnackbar: true, snackbarMessage });
    };

    handleSnackbarClose = () => {
        this.setState({
            showSnackbar: false,
            snackbarMessage: ''
        });
    };

    logout = async () => {
        try {
            await firebase.auth().signOut();
            localStorage.clear();
            this.setState({ firebaseToken: '', googleApisToken: '' });
        } catch (e) {
            console.error(e);
            this.showSnackbar('Erreur lors de la déconnexion');
        }
    };

    componentWillMount() {
        firebase.auth().onAuthStateChanged(async user => {
            this.setState({ loading: true });
            if (user) {
                try {
                    const snap = await firebase
                        .database()
                        .ref(`/users/${user.uid}`)
                        .once('value');

                    const { admin, ad_network } = snap.val();
                    this.setState({
                        isAuthenticated: true,
                        user: { isAdmin: admin, adNetwork: ad_network },
                        loading: false
                    });
                } catch (e) {
                    console.error(e);
                    this.setState({ loading: false });
                }
            } else {
                console.warn('onAuthStateChanged => user has been unlogged');
                this.setState({
                    isAuthenticated: false,
                    user: {},
                    loading: false
                });
            }
        });
    }

    setTokens = (firebaseToken, googleApisToken) => {
        localStorage.setItem('GOOGLE_APIS_TOKEN', googleApisToken);
        localStorage.setItem('FIREBASE_TOKEN', firebaseToken);
        this.setState({ firebaseToken, googleApisToken });
    };

    async componentDidMount() {
        const firebaseToken = await localStorage.getItem('FIREBASE_TOKEN');
        const googleApisToken = await localStorage.getItem('GOOGLE_APIS_TOKEN');
        this.setState({ firebaseToken, googleApisToken });
    }

    render() {
        const {
            showSnackbar,
            snackbarMessage,
            firebaseToken,
            googleApisToken,
            isAuthenticated,
            loading,
            user,
            appBarTitle
        } = this.state;
        return (
            <main className="App">
                {loading ? (
                    <CircularProgress style={styles.absoluteMiddle} size={50} />
                ) : (
                    <Router>
                        <div style={{ width: '100%' }}>
                            {isAuthenticated && <AppBar appBarTitle={appBarTitle} logout={this.logout} />}
                            <ProtectedRoute predicate={!isAuthenticated} path="/login" redirectTo={'/dashboard'}>
                                {props => (
                                    <LoginPage
                                        {...props}
                                        successLogin={this.successLogin}
                                        showSnackbar={this.showSnackbar}
                                        setTokens={this.setTokens}
                                    />
                                )}
                            </ProtectedRoute>

                            <Route
                                path="/dashboard"
                                render={props =>
                                    isAuthenticated ? (
                                        user.isAdmin ? (
                                            <AdminDashboard
                                                firebaseToken={firebaseToken}
                                                showSnackbar={this.showSnackbar}
                                                googleApisToken={googleApisToken}
                                                user={user}
                                            />
                                        ) : (
                                            <Dashboard {...props} user={user} setAppBarTitle={this.setAppBarTitle} />
                                        )
                                    ) : (
                                        <Redirect
                                            to={{
                                                pathname: '/login',
                                                state: { from: props.location }
                                            }}
                                        />
                                    )
                                }
                            />
                            <ProtectedRoute predicate={isAuthenticated} path="/profile" redirectTo={'/login'}>
                                {props => <Profile {...props} showSnackbar={this.showSnackbar} />}
                            </ProtectedRoute>
                            <Route
                                exact
                                path="/"
                                render={props => (
                                    <Redirect
                                        to={{
                                            pathname: '/login',
                                            state: { from: props.location }
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </Router>
                )}
                <Snackbar
                    open={showSnackbar}
                    message={<span>{snackbarMessage}</span>}
                    onClose={this.handleSnackbarClose}
                />
            </main>
        );
    }
}

const styles = {
    absoluteMiddle: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
};
const AppContainer = () => (
    <MuiThemeProvider theme={theme}>
        <App />
    </MuiThemeProvider>
);

export default AppContainer;
